import "./BookDetailComponent.scss";

import * as dayjs from "dayjs";
import "dayjs/locale/es";

import copy from "copy-to-clipboard";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getBook } from "../../../redux/actions/book";
import Arrows from "../../DesingSystem/Arrows/Arrows";
import Genres from "../../Genres/Genres";
import Icon from "../../Icon/Icon";
import AuthorItem from "../AuthorItem/AuthorItem";
import BuyBook from "../BuyBook/BuyBook";
import GeneralInfoBook from "../GeneralInfoBook/GeneralInfoBook";
import SinopsisBook from "../SinopsisBook/SinopsisBook";
import SliderBooks from "../SliderBooks/SliderBooks";
import Reviews from "../Reviews/Reviews";

function BookDetailComponent(props) {
  dayjs.locale("es");
  const dispatch = useDispatch();
  const bookdetail = useRef();

  const [currentIndex, setCurrentIndex] = useState(props.index);
  const books = useSelector((store) => store.book.books);
  const totalBooks = useSelector((store) => store.book.total);

  const [shareResult, setShareResult] = useState('');

  const general_information = {
    sales: props.book.sales ? props.book.sales : 0,
    publication: props.book ? dayjs(props.book.created_at).format('D MMM YYYY') : '--',
    last_edition: props.book ? dayjs(props.book.updated_at).format('D MMM YYYY') : '--',
    pages: props.book ? props.book.page_number : '--',
    language: props.book ? props.book.language : '--',
    isbn: props.book ? props.book.isbn : '--',
    likes: props.book ? props.book.likes : '--',
    dislikes: props.book ? props.book.dislikes : '--',
    neutral: props.book ? props.book.neutral : '--'
  };

  useEffect(() => {
    document.querySelector('meta[name=theme-color]').setAttribute('content', '#f7f7f7');
    return () => {
      document.querySelector('meta[name=theme-color]').setAttribute('content', '#f05a24');
    };
  });

  const onClickArrows = async (direction) => {
    if (direction === "left") {
      dispatch(getBook(books[currentIndex - 1]._id)).then(() => {
        setCurrentIndex(currentIndex - 1);
      });
    } else if (direction === "right") {
      dispatch(getBook(books[currentIndex + 1]._id)).then(() => {
        setCurrentIndex(currentIndex + 1);
      });
    }
  };

  const onRelatedClick = async (id) => {
    dispatch(getBook(id)).then(() => {
      bookdetail.current.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  const doShare = async (book) => {
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    const port = window.location.port;
    const base_url = `${protocol}//${domain}${port ? ":" + port : ""}/`;

    if (navigator.share) {
      const shareData = {
        title: book.title,
        url: `${base_url}descubre/${book.slug}`,
      };
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.log(err.stack);
      }
    } else {
      copy(`${base_url}descubre/${book.slug}`);
      setShareResult(true);
      setTimeout(() => setShareResult(false), 3000);
      //alert('Se ha copiado la url al portapapeles.');
    }
  };

  return props.book ? (
    <div className="book-detail-component">
      <div className="book-detail-component__container-image">
        <div className="book-detail-component__header-mobile">
          <h1 className="book-detail-component__header-mobile-title">Detalles del libro</h1>
          <Icon name="close" size="medium" onClick={props.hide} />
        </div>
        <div className="book-detail-component__container-image-icon">
          <Icon name="share" size="medium" color="principal" onClick={() => doShare(props.book)} />
          
          <div className="book-detail-component__section-likes">
            <Icon name="reviewLike" size="medium" color="secondary-3" />
            <span className="book__section-download-text">{props.book.likes ? props.book.likes : 0}</span>
            <Icon name="reviewNeutral" size="medium" color="secondary-3" />
            <span className="book__section-download-text">{props.book.neutral ? props.book.neutral : 0}</span>
            <Icon name="reviewDislike" size="medium" color="secondary-3" />
            <span className="book__section-download-text">{props.book.dislikes ? props.book.dislikes : 0}</span>
          </div>
        </div>
        <div className="book-detail-component__container-image-cover">
          <img
            className="book-detail-component__container-image-img"
            src={props.book ? props.book.picture_medium : ""}
            alt="img"
          />
        </div>
        <div className="book-detail-component__container-image-text">
          <div className="book-detail-component__container-image-text-justify">
            <p className="book-detail-component__container-image-text-title">
              {props.book ? props.book.title : "--"}
            </p>
            <span className="d-none d-md-inline-block"> · </span>
            <p className="book-detail-component__container-image-text-author">
              Por{" "}
              {props.book
                ? props.book.user.firstName + " " + props.book.user.lastName
                : "--"}
            </p>
          </div>

          {typeof props.index !== 'undefined' && books ?
            <Arrows
              size="medium"
              color="secondary-3"
              border="border-1"
              onClick={onClickArrows}
              showLeft={currentIndex === 0 ? false : true}
              showRight={currentIndex === (books.length - 1) ? false : true}
            />
            : null}
        </div>
      </div>
      <div className="book-detail-component__container-sinopsis" ref={bookdetail}>
        <SinopsisBook book={props.book} />
        {props.book.genre ?
          <div className="book-detail-component__container-genres">
            <p className="book-detail-component__container-genres-title">Género</p>
            <p className="sinopsis-book__sinopsis">{props.book.genre.tag}</p>
          </div>
          : null}
        <div className="book-detail-component__container-genres">
          <p className="book-detail-component__container-genres-title">Categorías</p>
          <Genres genres={props.book.categories} align="left" />
        </div>
        <GeneralInfoBook generalInfo={general_information} />
        <a href="/seguridad-producto-bookolicos.txt" target="_blank" className="book-detail-component__product-security">Seguridad de producto</a>
        <AuthorItem author={props.book.user} books={props.book.related_books} />
        <Reviews book={props.book} />
        {props.book.related_books.length ? (
          <div className="book-detail-component__container-other-books">
            <p className="book-detail-component__container-other-books-title">
              Más libros del autor
            </p>

            <div className="book-detail-component__container-other-books-img">
              {" "}
              <SliderBooks books={props.book.related_books} onRelatedClick={onRelatedClick} />
            </div>
          </div>
        ) : null}
        <BuyBook book={props.book} />
      </div>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={shareResult && shareResult === true}
      >
        <Modal.Body>
          <div>
            <h4 className="share-result">
              URL copiada en el portapapeles
            </h4>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  ) : null;
}

export default BookDetailComponent;
