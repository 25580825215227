import ls from "../services/localStorage";
import { types } from "../constants/constants";

export const getApiBooks = (attributes) => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}books${query}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiBooksByUser = (attributes) => {
  const esc = encodeURIComponent;
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}books/user/${attributes.user}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiBooksByCategory = (attributes) => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}books/category/${attributes.slug}/${query}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiNewBooks = (attributes) => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}new-books${query}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiFeaturedBooks = (attributes) => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}featured-books${query}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiBook = (bookId) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}books/id/${bookId}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiBookBySlug = (slug) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}books/slug/${slug}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiBookReviews = (attributes) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}books/id/${attributes.bookId}/reviews${ attributes.limit ? '?limit=' + attributes.limit : '' }${ attributes.offset ? '&offset=' + attributes.offset : '' }`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.body ? response.json() : {};
  });
};

export const postApiCreateBook = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}book/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token : token
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
  
};

export const putApiUpdateBook = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}books/update/id/${attributes.book._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token : token
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
  
};
